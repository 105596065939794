export default {
  path: "/areas",
  name: "Áreas",
  component: () => import("../views/areas"),
  meta: {
    title: "Áreas"
  },
  children: [
    {
      path: "listar",
      component: () => import("../views/areas/list"),
      meta: {
        title: "Listar Áreas"
      }
    },
    {
      path: "adicionar",
      component: () => import("../views/areas/new"),
      meta: {
        title: "Adicionar Área"
      }
    }
  ]
};
