<template>
  <v-app>
    <NavBar />
    <v-main>
      <v-container>
        <v-card outlined color="#e9ecef">
          <NavCumbs />
        </v-card>
      </v-container>

      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import NavBar from "@/views/layout/Navbar";
import Footer from "@/views/layout/Footer";
import NavCumbs from "@/components/Navcumbs";
export default {
  components: { Footer, NavBar, NavCumbs },
  data() {
    return {};
  }
};
</script>

<style></style>
