export default {
  path: "/scheduling-status",
  name: "Status de agendamentos",
  component: () => import("../views/scheduling-status/index"),
  meta: {
    title: "Status de evento"
  },

  children: [
    {
      path: "listar",
      component: () => import("../views/scheduling-status/list"),
      meta: {
        title: "Listar status de agendamentos"
      }
    },
    {
      path: "adicionar",
      component: () => import("../views/scheduling-status/new"),
      meta: {
        title: "Inserir status de agendamentos"
      }
    }
  ]
};
