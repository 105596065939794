export default {
  path: "/oferta",
  name: "Oferta",
  component: () => import("../views/offer/index.vue"),
  meta: {
    title: "Oferta"
  },

  children: [
    {
      path: "listar",
      component: () => import("../views/offer/list"),
      meta: {
        title: "Listar ofertas"
      }
    },
    {
      path: "adicionar",
      component: () => import("../views/offer/new.vue"),
      meta: {
        title: "Adicionar oferta"
      }
    }
  ]
};
