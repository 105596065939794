import Axios from "axios";

const URL_API = `${process.env.VUE_APP_API}`;
const axiosInstance = Axios.create({
  baseURL: URL_API
});

axiosInstance.interceptors.request.use(
  function(config) {
    const authToken = window.localStorage.token;
    if (authToken) {
      config.headers.common["Authorization"] = `Bearer ${authToken}`;
    }
    return config;
  },
  function(error) {
    console.log(error);
    return Promise.reject(error);
  }
);

export default {
  get(endpoint, body, config) {
    return axiosInstance.get(endpoint, config);
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint);
  },
  auth(body) {
    return Axios.post(`${URL_API}/auth`, body);
  }
};
