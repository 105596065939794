export default {
  path: "/times",
  name: "Times",
  component: () => import("../views/team"),
  meta: {
    title: "Times"
  },

  children: [
    {
      path: "listar",
      component: () => import("../views/team/list.vue"),
      meta: {
        title: "Listar times"
      }
    },
    {
      path: "adicionar",
      component: () => import("../views/team/new.vue"),
      meta: {
        title: "Novo Time"
      }
    }
  ]
};
