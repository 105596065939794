const authorized = {
  data() {
    return {
      profile: null
    };
  },
  created() {
    this.getPermission();
  },

  methods: {
    async getPermission() {
      try {
        const response = await this.$http.get("/user/profile");
        this.profile = response.data;
      } catch (error) {
        if (error.response.status === 500) {
          window.localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          window.localStorage.removeItem("token");
          this.$router.push("/");
          this.$toast.error(
            "Sua sessão expirou, faça login novamente para continuar"
          );
        }
      }
    }
  },
  computed: {
    isAdmin() {
      return (
        this.profile.role_type === "admin" || this.profile.role_type === "coord"
      );
    },
    canEdit() {
      return (
        this.profile.role_type === "admin" ||
        this.profile.role_type === "coord" ||
        this.profile.role_type === "analyst"
      );
    },
    canRemove() {
      return this.profile.role_type === "analyst";
    },
    canRead() {
      return (
        this.profile.role_type === "support" ||
        this.profile.role_type === "analyst"
      );
    }
  }
};

export default authorized;
