export default {
  path: "/criterios",
  name: "Critérios",
  component: () => import("../views/criterias"),
  meta: {
    title: "Critérios"
  },
  children: [
    {
      path: "listar",
      component: () => import("../views/criterias/list"),
      meta: {
        title: "Listar novos eventos"
      }
    },
    {
      path: "adicionar",
      component: () => import("../views/criterias/new"),
      meta: {
        title: "Adicionar critério"
      }
    }
  ]
};
