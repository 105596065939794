export default {
  path: "/usuarios",
  name: "Usuarios",
  component: () => import("../views/users"),
  meta: {
    title: "Usuarios"
  },
  children: [
    {
      path: "listar",
      component: () => import("../views/users/list"),
      meta: {
        title: "Listar Usuarios"
      }
    }
    /* {
      path: 'adicionar',
      component: () => import('../views/users/new'),
      meta: {
        title: 'Adicionar Usuarios'
      },
    }, */
  ]
};
