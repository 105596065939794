<template>
  <div v-if="profile">
    <v-app-bar app color="primary" dark>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <v-toolbar-title>Torre de Controle </v-toolbar-title>
      </div>

      <v-spacer></v-spacer>
      <v-menu offset-y :nudge-bottom="3" transition="scale-transition">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-toolbar-items>
                <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <v-icon x-large>mdi-account-circle</v-icon>
                </v-btn>
              </v-toolbar-items>
            </template>
            <span>Perfil</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item to="/perfil">
            <v-list-item-title>Alterar senha</v-list-item-title>
          </v-list-item>

          <v-list-item @click="logout()">
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer expand-on-hover mobile-breakpoint="0" app>
      <v-toolbar color="#1565C0" dark>
        <v-spacer></v-spacer>
        <v-toolbar-title> <v-icon>mdi-dots-vertical</v-icon> </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-list>
        <v-list-item v-for="i in menu" :key="i.label" :to="i.to">
          <!-- eslint-disable-line -->
          <template>
            <v-list-item-icon>
              <v-icon>{{ i.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="i.label"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
        <v-list-group :value="true" prepend-icon="mdi-cog" v-if="isAdmin">
          <template v-slot:activator>
            <v-list-item-title>Configurações</v-list-item-title>
          </template>

          <v-list-group
            v-for="item in menu_itens"
            :key="item.label"
            v-model="item.active"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.label"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.label"
              :to="child.to"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.label"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Authz from "../../mixins/authorized";
export default {
  name: "Navbar",
  mixins: [Authz],
  data() {
    return {
      menu: [
        {
          label: "Ofertas",
          icon: "mdi-calendar-check",
          to: "/oferta/listar"
        },
        {
          label: "Agendamentos",
          icon: "mdi-calendar-month",
          to: "/agendamentos/listar"
        },
        {
          label: "Novo Agendamento",
          icon: "mdi-calendar-plus",
          to: "/agendamento"
        }
      ],
      menu_itens: [
        {
          label: "Times",
          items: [
            { label: "Novo", icon: "", to: "/times/adicionar" },
            { label: "Listar", icon: "", to: "/times/listar" }
          ]
        },
        {
          label: "Usuários",
          items: [{ label: "Listar", icon: "", to: "/usuarios/listar" }]
        },
        {
          label: "Situações de OS",
          items: [
            { label: "Novo", icon: "", to: "/status/adicionar" },
            { label: "Listar", icon: "", to: "/status/listar" }
          ]
        },
        {
          label: "Status de agendamentos",

          items: [
            { label: "Novo", icon: "", to: "/scheduling-status/adicionar" },
            { label: "Listar", icon: "", to: "/scheduling-status/listar" }
          ]
        },
        {
          label: "Critérios",

          items: [
            { label: "Novo", icon: "", to: "/criterios/adicionar" },
            { label: "Listar", icon: "", to: "/criterios/listar" }
          ]
        },
        {
          label: "Áreas",

          items: [
            { label: "Novo", icon: "", to: "/areas/adicionar" },
            { label: "Listar", icon: "", to: "/areas/listar" }
          ]
        },
        {
          label: "Endereços",

          items: [
            { label: "Novo", icon: "", to: "/endereco/adicionar" },
            { label: "Listar", icon: "", to: "/endereco/listar" }
          ]
        }
      ]
    };
  },

  methods: {
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/");
    }
  }
};
</script>

<style></style>
