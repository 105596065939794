<template>
  <v-breadcrumbs divider="/"> </v-breadcrumbs>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style></style>
