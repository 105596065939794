export default {
  path: "/agendamentos",
  name: "Agendamentos",
  component: () => import("../views/schedules/index.vue"),
  meta: {
    title: "Agendamentos"
  },

  children: [
    {
      path: "listar",
      component: () => import("../views/schedules/list.vue"),
      meta: {
        title: "Agendamentos"
      }
    }
  ]
};
