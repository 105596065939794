export default {
  path: "/endereco",
  name: "Endereços",
  component: () => import("../views/address"),
  meta: {
    title: "Endereços"
  },
  children: [
    {
      path: "listar",
      component: () => import("../views/address/list"),
      meta: {
        title: "Listar Endereços"
      }
    },
    {
      path: "adicionar",
      component: () => import("../views/address/new"),
      meta: {
        title: "Adicionar Endereços"
      }
    }
  ]
};
