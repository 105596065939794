import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import EventStatusRoutes from "./event-status-router";
import CriteriasRoutes from "./criterias-routes";
import SchedulingStatusRoutes from "./scheduling-status-router";
import ClientTypeRoutes from "./client-type-router";
import AreasRoutes from "./area-routes";
import OfferRouter from "./offer-router";
import StepScheduling from "./steps-scheduling";
import SchedulesRouter from "./schedules-routes";
import AddressRoutes from "./address-routes";
import TeamRoutes from "./team-routes";
import UserRoutes from "./users-routes";
import Profile from "./profile-routes";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Auth",
    component: () => import("../views/Login"),
    props: true,

    meta: {
      title: "Torre de Controle - Entrar"
    }
  },

  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      login: true,
      title: "Home"
    },

    children: [
      EventStatusRoutes,
      CriteriasRoutes,
      SchedulingStatusRoutes,
      ClientTypeRoutes,
      AreasRoutes,
      AddressRoutes,
      OfferRouter,
      SchedulesRouter,
      TeamRoutes,
      UserRoutes,
      Profile
    ]
  },
  StepScheduling
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  if (to.matched.some(record => record.meta.login)) {
    if (!window.localStorage.token) {
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
