export default {
  path: "/tipos-cliente",
  name: "Tipos de cliente",
  component: () => import("../views/client-types/index.vue"),
  meta: {
    title: "Tipos de cliente"
  },

  children: [
    {
      path: "listar",
      component: () => import("../views/client-types/list"),
      meta: {
        title: "Listar tipos de cliente"
      }
    },
    {
      path: "adicionar",
      component: () => import("../views/client-types/new.vue"),
      meta: {
        title: "Adicionar tipo de cliente"
      }
    }
  ]
};
