import Vue from "vue";
import App from "./App.vue";
import router from "./routes";

import InputFacade from "vue-input-facade";

import vuetify from "./plugins/vuetify";

//methods api
import api from "@/services/api";

//Notification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import toastConfig from "./config/toast";

Vue.config.productionTip = false;

Vue.use(Toast, toastConfig);
Vue.use(InputFacade);
Vue.prototype.$http = api;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
